import axios from "axios";


const STEEM_API = "https://dashboard.blokfield.io/steem";
const SEREY_API = "https://dashboard.blokfield.io/serey";
const BLURT_API = "https://dashboard.blokfield.io/blurt";

export const getTransactions = async (network) => {
    let API_URL;
    if(network === 'steem') {
        API_URL = STEEM_API;
    } else if (network === 'blurt') {
        API_URL = BLURT_API;
    } else if (network === 'serey') {
        API_URL = SEREY_API;
    } else {
        return null;
    }
    const result = await axios(`${API_URL}/transactions/all`);

    return result.data;
}


export const getErrors = async (network) => {
    let API_URL;
    if(network === 'steem') {
        API_URL = STEEM_API;
    } else if (network === 'blurt') {
        API_URL = BLURT_API;
    } else if (network === 'serey') {
        API_URL = SEREY_API;
    } else {
        return null;
    }

    const result = await axios(`${API_URL}/errors`);

    return result.data;
}

export const retryTx = async(api, apiPass, source, id) => {
    let API_URL;
    if(api === 'steem') {
        API_URL = STEEM_API;
    } else if (api === 'blurt') {
        API_URL = BLURT_API;
    } else if (api === 'serey') {
        API_URL = SEREY_API;
    } else {
        return null;
    }

    var config = {
        method: 'post',
        url: `${API_URL}/transactions/process/${source}/${id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data : {
            api_key : apiPass
        }
      };

    const result = await axios(config)

    return result.data;
}
